import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import CONSTANTES from './Constantes.js'

// router setup
import router from './routes/router';
// plugin setup
Vue.use(DashboardPlugin);

import axios from 'axios';
//import { BIconLayoutTextWindowReverse } from 'bootstrap-vue';
//import { local } from 'd3';
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);


Vue.config.productionTip = false

Vue.mixin({
    created() {
        //console.log('[created] ' + this.$options.name)
    },
});

router.beforeEach((to, from, next) => {
    if (to.fullPath !== "/login" && !window.location.href.includes('login') && to.fullPath !== "/Recuperar" && !window.location.href.includes('Recuperar')) {
        if (localStorage.getItem('token') !== '') {
            axios({
                method: 'post',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                url: CONSTANTES.URL_RUTA_SERVICIOS + 'auth/valida-token'
            }).then(function(response) {
                var lsUsuario = JSON.parse(localStorage.usuario);
                if (to.meta.isTipo) {
                    if (lsUsuario.idPerfil == to.meta.isTipo) {
                        next();
                    } else {
                        router.push({ name: '404', params: { '0': '' } });
                    }
                } else {
                    next();
                }
            }).catch(function(error) {
                router.push({ name: 'login' });
            });
        } else {
            router.push({ name: 'login' });
        }
    } else {
        next();
    }
});



new Vue({
    router,
    axios,
    el: '#app',
    render: h => h(App),
});