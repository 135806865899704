import ContentLayout from '@/views/Layout/ContentLayout.vue';
import AuthLayout from '@/views/Layout/AuthLayout.vue';


const routes = [{
        path: '/',
        redirect: 'router page',
        component: ContentLayout,
        children: [
            /*---------------------------Master-------------------------*/
            {
                path: '/control-clientes',
                name: 'control clientes',
                //   meta: (route) => ({ requiresAuth: true, isTipo: '1' }),
                meta: { isTipo: "1" },
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Master/ControlClientes.vue')
            },
            {
                path: '/control-administradores',
                name: 'control administradores',
                meta: { isTipo: "1" },
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Master/ControlAdministradores.vue')
            },
            {
                path: '/control-responsables',
                name: 'control responsables',
                meta: { isTipo: "1" },
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Master/ControlResponsables.vue')
            },
            /*---------------------------Administrador-------------------------*/
            {
                path: '/control-capacitaciones',
                name: 'control capacitaciones',
                meta: { isTipo: "2" },
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Administrador/ControlCapacitaciones.vue')
            },
            {
                path: '/control-evaluaciones',
                name: 'control evaluaciones',
                meta: { isTipo: "2" },
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Administrador/ControlEvaluaciones.vue')
            },

            {
                path: '/mantenimiento-capacitacion/:idCapacitacion',
                name: 'mantenimiento capacitación',
                meta: { isTipo: "2" },
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Administrador/MantenimientoCapacitacion.vue')
            },
            {
                path: '/asignacion-capacitaciones',
                name: 'asignar capacitación',
                meta: { isTipo: "2" },
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Administrador/AsignarCapacitaciones.vue')
            },
            {
                path: '/reportes-estadisticas',
                name: 'generar reportes',
                meta: { isTipo: "2" },
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Administrador/GenerarReportes.vue')
            },
            /*---------------------------Responsable-------------------------*/
            {
                path: '/panel-responsable',
                name: 'panel responsable',
                meta: { isTipo: "3" },
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Responsable/PanelResponsable.vue')
            },
            {
                path: '/gestion-capacitacion/:id/:nombreCapacitacion',
                name: 'gestión capacitación',
                meta: { isTipo: "3" },
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Responsable/GestionCapacitacion.vue')
            },
            {
                path: '/control-trabajador',
                name: 'control trabajador',
                meta: { isTipo: "3" },
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Responsable/ControlTrabajadores.vue')
            },
            {
                path: '/reportes-capacitacion',
                name: 'reportes capacitación',
                meta: { isTipo: "3" },
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Responsable/ReportesCapacitacion.vue')
            },
            {
                path: '/gestion-evaluacion',
                name: 'evaluaciones trabajadores',
                meta: { isTipo: "3" },
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Responsable/GestionEvaluacion.vue')
            },
            /*---------------------------Trabajador-------------------------*/
            {
                path: '/panel-trabajador',
                name: 'panel trabajador',
                meta: { isTipo: "4" },
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Trabajador/PanelTrabajador.vue')
            },
            {
                path: '/contenido-capacitacion/:id/:nombreCapacitacion',
                name: 'contenido capacitación',
                meta: { isTipo: "4" },
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Trabajador/ContenidoCapacitacion.vue')
            },

            {
                path: '/comenzar-evaluacion/:idcaper/:id/:nombre',
                name: 'nueva evaluacion',
                meta: { isTipo: "4" },
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Trabajador/ContenidoEvaluacion.vue')
            },

            {
                path: '/panel-evaluacion',
                name: 'mis evaluaciones',
                meta: { isTipo: "4" },
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Trabajador/PanelEvaluaciones.vue')
            },

            /*---------------------------Generales-------------------------*/
            {
                path: '/perfil',
                name: 'mi perfil',
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Paginas/Perfil.vue')
            },
            {
                path: '/',
                name: '',
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Paginas/RouterPage.vue')
            },

            /*---------------------------Otros-------------------------*/


            {
                path: '/plantilla',
                name: 'plantilla',
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/plantilla.vue')
            },

        ]
    },
    {
        path: '/',
        redirect: 'login',
        component: AuthLayout,
        children: [{
                path: '/login',
                name: 'login',
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Paginas/Login.vue')
            },
            {
                path: '/recuperar',
                name: 'recuperar',
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/Paginas/RecuperarContrasena.vue')
            },
            {
                path: '*',
                name: '404',
                component: () =>
                    import ( /* webpackChunkName: "demo" */ '../views/NotFoundPage.vue')
            },
        ]
    }
];

export default routes;