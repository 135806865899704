 const IP_BACK_SERVER = 'back-capci.im-ra.net'
 const IP_FRONT_SERVER = 'capci.im-ra.net'

 const URL_RUTA_SERVICIOS = `https://${IP_BACK_SERVER}/`
 const URL_RUTA_FRONTEND = `https://${IP_FRONT_SERVER}/`

 // const IP_BACK_SERVER = 'localhost'
 // const IP_FRONT_SERVER = 'localhost'

 // const URL_RUTA_SERVICIOS = `http://${IP_BACK_SERVER}:3000/`
 // const URL_RUTA_FRONTEND = `http://${IP_FRONT_SERVER}/`

 const ERROR_404 = 404
 const ERROR_505 = 505
 const METHOD_POST = 'POST'
 const METHOD_GET = 'GET'
 const METHOD_DELETE = 'DELETE'
 const METHOD_PUT = 'PUT'
 const METHOD_HEAD = 'HEAD'
 const METHOD_OPTIONS = 'OPTIONS'

 const REGDNI = /^\d{8}$/
 const REGNOMBRES = /^[a-zA-Z áéíóúáéíóúÁÉÍÓÚñÑäÄëËïÏöÖüÜ]+$/i
 const REGTELEFONO = /^[1-9]\d{6,8}$/

 export default {
     URL_RUTA_SERVICIOS,
     URL_RUTA_FRONTEND,
     IP_BACK_SERVER,
     ERROR_404,
     ERROR_505,
     METHOD_POST,
     METHOD_GET,
     METHOD_DELETE,
     METHOD_PUT,
     METHOD_HEAD,
     METHOD_OPTIONS,
     REGDNI,
     REGNOMBRES,
     REGTELEFONO
 }