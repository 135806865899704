<template>
<nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" toggleable="lg" id="sidenav-main">
    <div class="container-fluid">

        <!--Toggler-->
        <navbar-toggle-button @click.native="showSidebar">
        </navbar-toggle-button>
        <router-link class="navbar-brand" to="/">
            <img :src="logo" class="navbar-brand-img" alt="...">
        </router-link>
        <div class="sidenav-toggler d-none d-xl-block active"><div class="sidenav-toggler-inner"><i class="sidenav-toggler-line"></i><i class="sidenav-toggler-line"></i><i class="sidenav-toggler-line"></i></div></div>

        <slot name="mobile-right">
            <ul class="nav align-items-center d-md-none">
                <!--    <base-dropdown class="nav-item" menu-on-right tag="li" title-tag="a">
                    <a slot="title-container" class="nav-link nav-link-icon" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                        <i class="ni ni-bell-55"></i>
                    </a>

                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">Something else here</a>
                </base-dropdown>-->
                <base-dropdown class="nav-item" menu-on-right tag="li" title-tag="a">
                    <a slot="title-container" class="nav-link" href="#" role="button">
                        <div class="media align-items-center">
                            <span class="avatar avatar-sm rounded-circle">
                                <img alt="Image placeholder" src="img/icons/icon_persona.png">
                            </span>
                        </div>
                    </a>

                    <div class=" dropdown-header noti-title">
                        <h6 class="text-overflow m-0">Bienvenido {{DescripcionPerfil}} </h6>
                    </div>
                    <router-link :to="{name:'mi perfil'}" class="dropdown-item">
                        <i class="ni ni-single-02"></i>
                        <span>Mi perfil</span>
                    </router-link>
               <!--     <router-link :to="{name:'404', params: { '0': '' }}" class="dropdown-item">
                        <i class="ni ni-settings-gear-65"></i>
                        <span>Configuración</span>
                    </router-link>
                  
                    <router-link :to="{name:'404', params: { '0': '' }}" class="dropdown-item">
                        <i class="ni ni-support-16"></i>
                        <span>Soporte</span>
                    </router-link>-->
                    <div class="dropdown-divider"></div>
                    <router-link :to="{name:'login'}" class="dropdown-item">
                        <i class="ni ni-user-run"></i>
                        <span>Salir</span>
                    </router-link>
                </base-dropdown>
            </ul>
        </slot>
        <slot></slot>
        <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">

            <div class="navbar-collapse-header d-md-none">
                <div class="row">
                    <div class="col-6 collapse-brand ">
                        <router-link to="/">
                            <img :src="logo">
                        </router-link>
                    </div>
                    <div class="col-6 collapse-close">
                        <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
                    </div>
                </div>
            </div>

            <template v-if="razonSocial!=null">
                <h6 class="navbar-heading text-muted text-center">{{razonSocial}}</h6>
            </template>
            <!--sidebar-->
            <ul class="navbar-nav">
                <slot name="links">
                </slot>
            </ul>
            <!--Divider-->
         <!--   <hr class="my-3">-->
            <!--Heading-->
          <!--  <h6 class="navbar-heading text-muted">Documenstation</h6>-->
            <!--Navigation-->
           <!--     <ul class="navbar-nav mb-md-3">
                <li class="nav-item">
                    <a class="nav-link" href="https://www.creative-tim.com/learning-lab/bootstrap-vue/alerts/argon-dashboard">
                        <i class="ni ni-spaceship"></i> Getting started
                    </a>
                </li>
                 <li class="nav-item">
                    <a class="nav-link" href="https://www.creative-tim.com/learning-lab/bootstrap-vue/colors/argon-dashboard">
                        <i class="ni ni-palette"></i> Foundation
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://www.creative-tim.com/learning-lab/bootstrap-vue/alerts/argon-dashboard">
                        <i class="ni ni-ui-04"></i> Components
                    </a>
                </li>
            </ul>-->
        </div>
    </div>
</nav>
</template>

<script>
import NavbarToggleButton from '@/components/NavbarToggleButton'

export default {
    name: 'sidebar',
    components: {
        NavbarToggleButton
    },
    data() {
        return {
            DescripcionPerfil: null,
            razonSocial: null
        };
    },
    props: {
        logo: {
            type: String,
            default: 'img/brand/logo.png',
            description: 'Capci'
        },
        autoClose: {
            type: Boolean,
            default: true,
            description: 'Whether sidebar should autoclose on mobile when clicking an item'
        }
    },
    provide() {
        return {
            autoClose: this.autoClose
        };
    },
    methods: {
        closeSidebar() {
            this.$sidebar.displaySidebar(false)
        },
        showSidebar() {
            this.$sidebar.displaySidebar(true)
        }
    },
    beforeDestroy() {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false;
        }
    },
    mounted() {
   if (localStorage.usuario) {
    
            let vDesc = "";
            var lsUsuario = JSON.parse(localStorage.usuario);
            this.perfil = lsUsuario.idPerfil;
            if (this.perfil == 1) {
                vDesc = "Master";
            } else if (this.perfil == 2) {
                vDesc = "Administrador";
            } else if (this.perfil == 3) {
                vDesc = "Responsable";
            } else {
                vDesc = "Trabajador";
            }
            this.DescripcionPerfil = vDesc;
             if (lsUsuario.razonSocial != null) {
                this.razonSocial = lsUsuario.razonSocial
            }

        }
    }
};
</script>
